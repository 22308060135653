import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button, Card,
  CardBody, Col,
  Form, Row
} from 'reactstrap';
import DataNotFound from '../../components/DataNotFound';
import LoadingCard from '../../components/Loading/LoadingCard';
import Notification from '../../components/Notifications/Notification';
import Api from '../../config/Api';

const AvaliacaoEntrega = (props) => {
  const location = useLocation();
  const [api, setApi] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [perguntas, setPerguntas] = useState([]);
  const [respondido, setRespondido] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [hovers, setHovers] = useState([]);

  const abrirNotificacaoErro = (message) => Notification.createNotification('error', message);

  useEffect(() => {
    const getFormularioInfo = async () => {
      const searchValue = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (!searchValue.formularioId || !searchValue.rotaPontoId) {
        setDataLoaded(true);
        return;
      }

      try {
        const { data: formularioRespostas } = await api.getRespostas({
          formularioId: searchValue.formularioId,
          rotaPontoId: searchValue.rotaPontoId,
        });

        if (formularioRespostas && formularioRespostas.length) {
          setRespondido(true);
        } else {
          const { data: formularioPerguntas } = await api.getPerguntasPorFormulario(searchValue.formularioId);

          const initialValues = formularioPerguntas.map((pergunta) => ({
            perguntaId: pergunta.id,
            valor: 0,
          }));

          setPerguntas(formularioPerguntas);
          setRatings(initialValues)
          setHovers(initialValues)
        }
        setDataLoaded(true);
      } catch (error) {
        window.alert('Não foi possível buscar os dados do formulário.');
        console.log(error);
        setDataLoaded(true);
      }

    }

    if (api) {
      getFormularioInfo();
    }
  }, [api, location.search])

  useEffect(() => {
    async function loginAsync() {
      try {
        const { data } = await Api.login();

        if (data.token) {
          const { token } = data;

          setApi(new Api(token, (err) => Promise.reject(err)));

        } else {
          props.history.push('/');
          window.alert('Não foi possível buscar os dados da entrega.');
        }
      } catch (error) {
        props.history.push('/');
        window.alert('Não foi possível buscar os dados da entrega.');
      }
    }

    if (!api && location.search && !dataLoaded) {
      loginAsync();
    } else if (!location.search && dataLoaded) {
      setDataLoaded(false);
    }
  }, [api, dataLoaded, location.search, props.history])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (perguntas.length !== ratings.length) {
      const confirm = window.confirm('Existem perguntas não respondidas. Deseja enviar mesmo assim?');

      if (!confirm) return;
    }

    const { formularioId, rotaPontoId } = qs.parse(location.search, { ignoreQueryPrefix: true });

    const { data } = await api.sendRespostas({ formularioId, rotaPontoId, respostas: ratings });

    if (data.status) {
      setRespondido(true);
    } else {
      abrirNotificacaoErro('Ocorreu um erro ao enviara a avaliação');
    }
  }

  const renderButton = (perguntaId, index) => {
    const { valor: rating } = ratings.find((r) => r.perguntaId === perguntaId);
    const { valor: hover } = hovers.find((h) => h.perguntaId === perguntaId);

    const getRatings = () => ratings.map((r) => {
      if (r.perguntaId === perguntaId) {
        return { ...r, valor: index }
      }
      return r;
    });

    const getHovers = () => hovers.map((h) => {
      if (h.perguntaId === perguntaId) {
        return { ...h, valor: index };
      }
      return h;
    })

    return (
      <button
        key={`${perguntaId}-${index}`}
        type="button"
        className={`star-button ${index <= (hover || rating) ? "on" : "off"}`}
        onClick={() => { setRatings(getRatings()) }}
        onMouseEnter={() => setHovers(getHovers())}
        onMouseLeave={() => setHovers(ratings)}
      >
        <span>&#9733;</span>
      </button>
    );
  }

  const renderCardBodyContent = () => {
    if (dataLoaded) {
      if (perguntas.length && !respondido) {
        return (
          <Form onSubmit={handleSubmit}>
            {perguntas.map((pergunta, index) => (
              <Row key={pergunta.id || index}>
                <Col md={12}>
                  <h4>{pergunta.descricaoPergunta}</h4>
                </Col>
                <Col md={12}>
                  <div>
                    {[...Array(5)].map((_star, index) => {
                      index += 1;
                      return renderButton(pergunta.id, index);
                    })}
                  </div>
                </Col>
                <Col md={12}>
                  <i>{pergunta.descricaoTipo}</i>
                </Col>
              </Row>
            ))}
            <Row>
              <Col md="3">
                <Button
                  type='submit'
                  color="primary">
                  Enviar
                  {' '}
                  <i className="fas fa-done" />
                </Button>
              </Col>
            </Row>
          </Form>
        );
      } else if (respondido) {
        return (
          <div className="text-center">
            <p className='text-justify h3 text-center'>
              {'Avaliação respondida!'}
            </p>
          </div >
        );
      } else {
        return <DataNotFound text="Nenhum formulário encontrado" />;
      }
    } else {
      return <LoadingCard />;
    }
  }

  return (
    <div className="content">
      <Row>
        <Col xs={12}>
          <Card className="content-tracking">
            <CardBody>
              {renderCardBodyContent()}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AvaliacaoEntrega;