import Tracking from '../views/Tracking/Tracking';
import AvaliacaoEntrega from '../views/AvaliacaoEntrega/AvaliacaoEntrega';

const dashRoutes = [
  {
    path: '/',
    name: 'Tracking',
    icon: 'fas fa-flag-checkered',
    component: Tracking,
    visible: true,
  }, {
    path: '/avaliacao-entrega',
    name: 'Avaliação de Entrega',
    icon: 'fab fa-wbforms',
    component: AvaliacaoEntrega,
    visible: true,
  }
];
export default dashRoutes;
