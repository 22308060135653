import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Moment from 'moment';
import 'react-vertical-timeline-component/style.min.css';
import { Button } from 'reactstrap';
import DataNotFound from 'components/DataNotFound';

const Alertas = (props) => {
  const { alertas } = props.documentSelected;

  if(!alertas || !alertas.length) {
    return (
      <DataNotFound
        text="Não foi possível localizar os alertas da entrega."
        modal
      />
    );
  }

  return (
    <VerticalTimeline>
      {alertas.map((alerta) => {
        const { dataAlerta, dataSolucao, nome, latitude, longitude } = alerta;

        let dateToShow = '';
        let iconBackground = '#9e9e9e';

        const hasAlertDate = !!dataAlerta;
        const hasSolutionDate = !!dataSolucao;

        if (hasAlertDate && !hasSolutionDate) {
          const alertDateFormated = Moment(dataAlerta).format('DD/MM/YYYY HH:mm');
          dateToShow = `Alerta: ${alertDateFormated}`;
          iconBackground = '#023047'
        }

        if (hasAlertDate && hasSolutionDate) {
          const alertDateFormated = Moment(dataAlerta).format('DD/MM/YYYY HH:mm');
          const solutionDateFormated = Moment(dataSolucao).format('DD/MM/YYYY HH:mm');
          dateToShow = `Alerta: ${alertDateFormated} Solução: ${solutionDateFormated}`;
          iconBackground = '#e63946';
        }

        return (
          <VerticalTimelineElement
            position="right"
            className="vertical-timeline-element--work timeline-content"
            contentArrowStyle={{ display: 'none' }}
            iconClassName='cursor-default'
            date={dateToShow}
            dateClassName='w-50'
            iconStyle={{
              background: iconBackground,
              color: 'white',
              fontSize: '18px',
            }}
            icon={
              <i className="fas fa-bell" />
            }
          >
            <div className='my-2'>
              <p className="mt-0">
                {nome}
              </p>
              {!!latitude && !!longitude && (
                <Button
                  color="danger"
                  onClick={() => {
                    const baseUrlMaps = 'https://www.google.com/maps/search/?api=1&query=';
                    window.open(`${baseUrlMaps}${latitude},${longitude}`, "_blank")
                  }}>
                  Ver no mapa
                  {' '}
                  <i class="fas fa-location-arrow"></i>
                </Button>
              )}
            </div>

          </VerticalTimelineElement>

        )
      })}
    </VerticalTimeline>
  );
};

export default Alertas;
