import React from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import SearchEmpty from '../../assets/img/search-empty.gif';

const PageNotFound = (props) => {
  const handleGoBack = (e) => {
    props.history.push('/');
  }

  return (
    <div className="content">
      <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Col md={6}>
          <Card>
            <CardBody>
              <div className="text-center">
                <h3 className='text-justify text-center'>
                  {props.text || '404 - Página não encontrada'}
                </h3>
                <img
                  src={SearchEmpty}
                  alt="Não encontrado"
                  height="400px"
                  width="400px"
                  style={{ objectFit: 'contain' }}
                />
              </div>
            </CardBody>
            <CardFooter className="card-chart">
              <Button
                color="primary"
                onClick={handleGoBack}>
                Voltar
                {' '}
                <i className="fas fa-return" />
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default PageNotFound;
