import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { NotificationContainer } from 'react-notifications';
import { Router, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss';
import './assets/demo/demo.css';
import './assets/attivilog/custom.css';
import './assets/attivilog/rotas-mapa.css';
import './assets/attivilog/popup-mapa.css';
import './assets/attivilog/avaliacao-entrega.css';

import indexRoutes from './routes/index.jsx';

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <div className="top-element">
      <NotificationContainer />
    </div>
    <Switch>
      {indexRoutes.map((prop, key) => <Route path={prop.path} key={key} component={prop.component} />)}
    </Switch>
  </Router>,
  document.getElementById('root'),
);
