import Footer from "../../components/Footer/Footer";
import React, { useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router-dom";
import dashboardRoutes from "../../routes/dashboard";
import PageNotFound from "../../components/PageNotFound";


const Dashboard = (props) => {
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const mainPanel = useRef();

  useEffect(() => {
    setAllowedRoutes(dashboardRoutes)
  }, [])

  return (
    <div className='wrapper'>
      <div className='main-panel' ref={mainPanel}>
        <Switch>
          {allowedRoutes.map((prop, key) => (
            <Route
              exact
              path={prop.path} 
              component={prop.component} 
              key={key} 
            />
          ))}
          <Route path="*" component={PageNotFound} />
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
}

export default Dashboard;