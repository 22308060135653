import React from 'react';
import SearchEmpty from '../../assets/img/search-empty.gif';

const DataNotFound = (props) => {
  return (
    <div className="text-center">
      <p 
        className={`text-justify ${!props.modal ? 'h6 text-center' : ''}`}
      >
        {props.text || 'Não foi possível buscar os dados da entrega'}
      </p>
      <img
        src={SearchEmpty}
        alt="Não encontrado"
        height="400px"
        width="400px"
        style={{ objectFit: 'contain' }}
      />
    </div >
  );
};

export default DataNotFound;
