import axios from 'axios';

const tmsBackEndApi = process.env.REACT_APP_TMS_BACK_END_URL;
const controleUsuariosApi = process.env.REACT_APP_CONTROLE_DE_USUARIOS_URL;
const trackingUsername = process.env.REACT_APP_USERNAME_TRACKING;
const trackingPassword = process.env.REACT_APP_PASSWORD_TRACKING;
export default class Api {
  constructor(token, onError) {
    this.axios = axios.create({
      baseURL: tmsBackEndApi,
      headers: {
        Authorization: token,
      },
    });

    this.axios.interceptors.response.use((response) => response, onError);
  }

  static login() {
    return axios.post(`${controleUsuariosApi}/login`, { username: trackingUsername, password: trackingPassword });
  }
  
  static validateToken(token) {
    return axios.post(`${controleUsuariosApi}/validate-token`, { token });
  }

  // Tracking

  searchDeliveriesAdvanced(searchValue) {
    return this.axios.post('/tracking/whitelist/search-deliveries-advanced', { searchValue });
  }

  getComprovanteFromS3(chave) {
    return this.axios.post('/tracking/whitelist/get-comprovante-s3', { chave });
  }

  getDeliveryPositionByKey(chave) {
    return this.axios.post('/tracking/whitelist/get-position-delivery', { chave });
  }

  // Avaliação de entrega
  
  getPerguntasPorFormulario(formularioId) {
    return this.axios.post('/formulario-pergunta/whitelist/por-formulario', { formularioId });
  }

  sendRespostas(payload) {
    return this.axios.post('/formulario-resposta/whitelist/create-respostas', payload);
  }

  getRespostas(payload) {
    return this.axios.post('/formulario-resposta/whitelist/search', payload);
  }

}
