import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

class LodingCard extends Component {
  render() {
    return (
      <div className="text-center">
        <p className="h6 text-center">Carregando...</p>
        <div className="loadingCard">
          <Spinner style={{ width: '10rem', height: '10rem' }} color="success" />{' '}
        </div>
      </div >
    );
  }
}

export default LodingCard;
