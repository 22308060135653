import L from 'leaflet';

export default function getIconDeliveryMarker(colorCode, icon) {
  const iconDelivery = new L.DivIcon({
    className: 'delivery-custom-icon',
    html: `<div><svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4375 39.2188C12.1875 37.3958 10.7552 35.3385 9.14062 33.0469C7.57812 30.7552 6.45833 29.1406 5.78125 28.2031C5.10417 27.2656 4.32292 26.1198 3.4375 24.7656C2.55208 23.3594 1.95312 22.3698 1.64062 21.7969C1.38021 21.224 1.06771 20.4948 0.703125 19.6094C0.390625 18.724 0.182292 17.9688 0.078125 17.3438C0.0260417 16.6667 0 15.8854 0 15C0 10.8333 1.45833 7.29167 4.375 4.375C7.29167 1.45833 10.8333 0 15 0C19.1667 0 22.7083 1.45833 25.625 4.375C28.5417 7.29167 30 10.8333 30 15C30 15.8854 29.9479 16.6667 29.8438 17.3438C29.7917 17.9688 29.5833 18.724 29.2188 19.6094C28.9062 20.4948 28.5938 21.224 28.2812 21.7969C28.0208 22.3698 27.4479 23.3594 26.5625 24.7656C25.6771 26.1198 24.8958 27.2656 24.2188 28.2031C23.5417 29.1406 22.3958 30.7552 20.7812 33.0469C19.2188 35.3385 17.8125 37.3958 16.5625 39.2188C16.1979 39.7396 15.6771 40 15 40C14.3229 40 13.8021 39.7396 13.4375 39.2188Z" fill="${colorCode}"/>
    <circle cx="15" cy="15" r="11" fill="white"/>
    <path d="M22.625 16.75C22.7344 16.75 22.8203 16.7891 22.8828 16.8672C22.9609 16.9297 23 17.0156 23 17.125V17.875C23 17.9844 22.9609 18.0781 22.8828 18.1562C22.8203 18.2188 22.7344 18.25 22.625 18.25H21.5C21.5 18.875 21.2812 19.4062 20.8438 19.8438C20.4062 20.2812 19.875 20.5 19.25 20.5C18.625 20.5 18.0938 20.2812 17.6562 19.8438C17.2188 19.4062 17 18.875 17 18.25H14C14 18.875 13.7812 19.4062 13.3438 19.8438C12.9062 20.2812 12.375 20.5 11.75 20.5C11.125 20.5 10.5938 20.2812 10.1562 19.8438C9.71875 19.4062 9.5 18.875 9.5 18.25H9.125C8.8125 18.25 8.54688 18.1406 8.32812 17.9219C8.10938 17.7031 8 17.4375 8 17.125V9.625C8 9.3125 8.10938 9.04688 8.32812 8.82812C8.54688 8.60938 8.8125 8.5 9.125 8.5H16.625C16.9375 8.5 17.2031 8.60938 17.4219 8.82812C17.6406 9.04688 17.75 9.3125 17.75 9.625V10.75H18.7812C19.0938 10.75 19.3594 10.8594 19.5781 11.0781L21.9219 13.4219C22.1406 13.6406 22.25 13.9062 22.25 14.2188V16.75H22.625ZM10.9531 19.0469C11.1719 19.2656 11.4375 19.375 11.75 19.375C12.0625 19.375 12.3281 19.2656 12.5469 19.0469C12.7656 18.8281 12.875 18.5625 12.875 18.25C12.875 17.9375 12.7656 17.6719 12.5469 17.4531C12.3281 17.2344 12.0625 17.125 11.75 17.125C11.4375 17.125 11.1719 17.2344 10.9531 17.4531C10.7344 17.6719 10.625 17.9375 10.625 18.25C10.625 18.5625 10.7344 18.8281 10.9531 19.0469ZM18.4531 19.0469C18.6719 19.2656 18.9375 19.375 19.25 19.375C19.5625 19.375 19.8281 19.2656 20.0469 19.0469C20.2656 18.8281 20.375 18.5625 20.375 18.25C20.375 17.9375 20.2656 17.6719 20.0469 17.4531C19.8281 17.2344 19.5625 17.125 19.25 17.125C18.9375 17.125 18.6719 17.2344 18.4531 17.4531C18.2344 17.6719 18.125 17.9375 18.125 18.25C18.125 18.5625 18.2344 18.8281 18.4531 19.0469ZM21.125 14.5V14.2188L18.7812 11.875H17.75V14.5H21.125Z" fill="${colorCode}"/>
    </svg>
    </div>`,
    iconAnchor: [10, 40],
    popupAnchor: [5, -40],
    iconSize: [40, 40],
  });
  const iconBuilding = new L.DivIcon({
    className: 'delivery-custom-icon',
    html: `<div><svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4375 39.2188C12.1875 37.3958 10.7552 35.3385 9.14062 33.0469C7.57812 30.7552 6.45833 29.1406 5.78125 28.2031C5.10417 27.2656 4.32292 26.1198 3.4375 24.7656C2.55208 23.3594 1.95312 22.3698 1.64062 21.7969C1.38021 21.224 1.06771 20.4948 0.703125 19.6094C0.390625 18.724 0.182292 17.9688 0.078125 17.3438C0.0260417 16.6667 0 15.8854 0 15C0 10.8333 1.45833 7.29167 4.375 4.375C7.29167 1.45833 10.8333 0 15 0C19.1667 0 22.7083 1.45833 25.625 4.375C28.5417 7.29167 30 10.8333 30 15C30 15.8854 29.9479 16.6667 29.8438 17.3438C29.7917 17.9688 29.5833 18.724 29.2188 19.6094C28.9062 20.4948 28.5938 21.224 28.2812 21.7969C28.0208 22.3698 27.4479 23.3594 26.5625 24.7656C25.6771 26.1198 24.8958 27.2656 24.2188 28.2031C23.5417 29.1406 22.3958 30.7552 20.7812 33.0469C19.2188 35.3385 17.8125 37.3958 16.5625 39.2188C16.1979 39.7396 15.6771 40 15 40C14.3229 40 13.8021 39.7396 13.4375 39.2188Z" fill="${colorCode}"/>
    <circle cx="15" cy="15" r="11" fill="white"/>
      <svg width="16" height="16" viewBox="0 0 91 91" x="7" y = "7">
        <path d="M78.957,0H12.873c-1.104,0-2,0.896-2,2v87.828c0,1.104,0.896,2,2,2h19.375c1.104,0,2-0.896,2-2V73.914h23.333v15.914   c0,1.104,0.896,2,2,2h19.375c1.104,0,2-0.896,2-2V2C80.957,0.895,80.06,0,78.957,0z M32.998,58.997c0,0.829-0.671,1.5-1.5,1.5   h-8.917c-0.829,0-1.5-0.671-1.5-1.5V50.08c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V58.997z M32.998,40.122   c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5v-8.917c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5   V40.122z M32.998,21.247c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V12.33c0-0.829,0.671-1.5,1.5-1.5h8.917   c0.829,0,1.5,0.671,1.5,1.5V21.247z M51.873,58.997c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V50.08   c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V58.997z M51.873,40.122c0,0.829-0.671,1.5-1.5,1.5h-8.917   c-0.829,0-1.5-0.671-1.5-1.5v-8.917c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V40.122z M51.873,21.247   c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V12.33c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5   V21.247z M70.748,58.997c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V50.08c0-0.829,0.671-1.5,1.5-1.5h8.917   c0.829,0,1.5,0.671,1.5,1.5V58.997z M70.748,40.122c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5v-8.917   c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V40.122z M70.748,21.247c0,0.829-0.671,1.5-1.5,1.5h-8.917   c-0.829,0-1.5-0.671-1.5-1.5V12.33c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V21.247z" fill="${colorCode}"/>
      </svg>    
    </svg>
    </div>`,
    iconAnchor: [10, 40],
    popupAnchor: [5, -40],
    iconSize: [40, 40],
  });
  if (icon === 'DELIVERY') {
    return iconDelivery;
  }
  if (icon === 'BUILDING') {
    return iconBuilding;
  }
}
