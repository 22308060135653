import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
// style css
import 'react-image-gallery/styles/scss/image-gallery.scss';
import DataNotFound from '../../components/DataNotFound';


const Comprovante = (props) => {
  const [arquivo, setArquivo] = useState('');

  useEffect(() => {
    const getFile = async () => {
      try {
        const { data } = await props.api.getComprovanteFromS3(props.documentSelected);
        setArquivo(data);
      } catch (error) {
        setArquivo(null);
      }
    };
    getFile();
  }, [props.api, props.documentSelected]);

  if (!arquivo.message && arquivo.length) {
    return (
      <ImageGallery
        items={arquivo}
        showThumbnails={false}
        showPlayButton={false}
        showIndex
      />
    );
  }
  return (
    <DataNotFound
      text="Não foi possível localizar o comprovante da entrega."
      modal
    />
  );
};

export default Comprovante;
