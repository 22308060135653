import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Moment from 'moment';
import 'react-vertical-timeline-component/style.min.css';

const Timeline = (props) => {
  const {
    dataEmissao,
    inicioDeRota,
    dataEntrega,
    situacaoEntrega,
  } = props.documentSelected;

  let chegadaNoPonto;
  let inicioEntrega;
  let fimEntrega;
  let saidaPonto;

  if (props.documentSelected.temposEntrega) {
    [props.documentSelected.temposEntrega].forEach((tempoEntrega) => {
      const { informacao } = tempoEntrega;
      if (informacao === 'CHEGADA_PONTO') {
        chegadaNoPonto = tempoEntrega.dataEvento;
      }
      if (informacao === 'FIM_ENTREGA') {
        fimEntrega = tempoEntrega.dataEvento;
      }
      if (informacao === 'INICIO_ENTREGA') {
        inicioEntrega = tempoEntrega.dataEvento;
      }
      if (informacao === 'SAIDA_PONTO') {
        saidaPonto = tempoEntrega.dataEvento;
      }
    });
  }

  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={dataEmissao ? Moment(dataEmissao).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: dataEmissao ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-warehouse" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            Mercadoria recebida pela transportadora.
          </p>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={inicioDeRota ? Moment(inicioDeRota).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: inicioDeRota ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-truck" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            Mercadoria recebida pela transportadora.
            Em rota de Entrega.
          </p>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={chegadaNoPonto ? Moment(chegadaNoPonto).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: chegadaNoPonto ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-city" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            Chegada no ponto de descarga.
          </p>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={inicioEntrega ? Moment(inicioEntrega).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: inicioEntrega ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-dolly" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            Início da entrega.
          </p>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={dataEntrega ? Moment(dataEntrega).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: dataEntrega ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-check" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            {dataEntrega && (
              situacaoEntrega === 'ENTREGUE' ? 'Entregue.' : 'Em Ocorrência'
            )}
            {!dataEntrega && (
              'Entregue.'
            )}
          </p>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={fimEntrega ? Moment(fimEntrega).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: fimEntrega ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-clipboard-check" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            Fim de Entrega.
          </p>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        position="right"
        className="vertical-timeline-element--work timeline-content"
        contentStyle={{ boxShadow: '0!important' }}
        contentArrowStyle={{ display: 'none' }}
        date={saidaPonto ? Moment(saidaPonto).format('DD/MM/YYYY HH:mm') : ''}
        iconStyle={{
          background: saidaPonto ? '#e63946' : '#9e9e9e',
          color: 'white',
          fontSize: '18px',
        }}
        icon={
          <i className="fas fa-shipping-fast" />
        }
      >
        <div className='my-2'>
          <p className="mt-0">
            Saída do ponto.
          </p>
        </div>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};

export default Timeline;
