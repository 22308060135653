import DataNotFound from 'components/DataNotFound';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Map, Marker, Popup, TileLayer
} from 'react-leaflet';
import getIconDeliveryMarker from './TrackingMarker';

const Tracking = (props) => {
  const { api, documentSelected } = props;

  const zoom = 13;
  const [dataLoaded, setdataLoaded] = useState(false);
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [destinatarioEndereco, setdestinatarioEndereco] = useState(documentSelected.destinatarioEndereco)

  useEffect(() => {
    getDeliveryPosition();
  })

  const getDeliveryPosition = async () => {
    const { data: position } = await api.getDeliveryPositionByKey(documentSelected);

    if (position && position.latitude && position.longitude) {
      setlatitude(position.latitude);
      setlongitude(position.longitude);
      setdestinatarioEndereco(documentSelected.destinatarioEndereco);
      setdataLoaded(true);
    } else {
      setlatitude(-14.94478488);
      setlongitude(-50.00976563);
    }
  }

  const getConcatAddress = () => {
    if (destinatarioEndereco && destinatarioEndereco.length) {
      const {
        logradouro, numero, bairro, cep,
      } = destinatarioEndereco[0];
      return `${logradouro}, ${numero}, ${bairro}, ${cep}`;
    }

    return '';
  }

  const handleDefaultCoordenate = (property) => {
    if (destinatarioEndereco && destinatarioEndereco.length) {
      return property === 'latitude'
        ? destinatarioEndereco[0].latitude
        : destinatarioEndereco[0].longitude;
    }

    return property === 'latitude'
      ? -14.94478488
      : -50.00976563;
  }

  return (
    dataLoaded ? (
      <div className="delivery-location-map">
        <Map center={[latitude, longitude]} zoom={zoom} preferCanvas>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            key={1}
            position={[handleDefaultCoordenate('latitude'), handleDefaultCoordenate('longitude')]}
            icon={getIconDeliveryMarker('green', 'BUILDING')}
          >
            <Popup
              minWidth={240}
            >
              <div className="map-popup-latlng">
                <span>{documentSelected.destinatarioNome}</span>
                <p>
                  {getConcatAddress()}
                </p>
              </div>
            </Popup>
          </Marker>
          <Marker
            key={0}
            position={[latitude, longitude]}
            icon={getIconDeliveryMarker('#01579b', 'DELIVERY')}
            zIndexOffset={1000}
          >
            <Popup>
              Entrega realizada em:
              {' '}
              {documentSelected.dataEntrega
                ? Moment(documentSelected.dataEntrega).format('DD/MM/YYYY HH:mm') : ''}
            </Popup>
          </Marker>
        </Map>
      </div>
    ) : (
      <DataNotFound
        text="Não foi possível capturar a localização do veículo nesta entrega.
        Verifique se ele está em uma área de sombra ou se o aplicativo
        está realizando a comunicação corretamente."
        modal
      />
    )
  );
}

export default Tracking;